var exports = {};

exports = function encodeUtf8(input) {
  var result = [];
  var size = input.length;

  for (var index = 0; index < size; index++) {
    var point = input.charCodeAt(index);

    if (point >= 55296 && point <= 56319 && size > index + 1) {
      var second = input.charCodeAt(index + 1);

      if (second >= 56320 && second <= 57343) {
        // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
        point = (point - 55296) * 1024 + second - 56320 + 65536;
        index += 1;
      }
    } // US-ASCII


    if (point < 128) {
      result.push(point);
      continue;
    } // 2-byte UTF-8


    if (point < 2048) {
      result.push(point >> 6 | 192);
      result.push(point & 63 | 128);
      continue;
    } // 3-byte UTF-8


    if (point < 55296 || point >= 57344 && point < 65536) {
      result.push(point >> 12 | 224);
      result.push(point >> 6 & 63 | 128);
      result.push(point & 63 | 128);
      continue;
    } // 4-byte UTF-8


    if (point >= 65536 && point <= 1114111) {
      result.push(point >> 18 | 240);
      result.push(point >> 12 & 63 | 128);
      result.push(point >> 6 & 63 | 128);
      result.push(point & 63 | 128);
      continue;
    } // Invalid character


    result.push(239, 191, 189);
  }

  return new Uint8Array(result).buffer;
};

export default exports;